@import "../../src/scss/variables.scss";
@import "../../src/scss/fonts";
@import "../../src/scss/mixins";


.block_text{
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
}
.block_text_content{
    padding: 20px 40px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.80);
}
.slider_bl{
    overflow: hidden;
    height: 100vh;
    position: relative;
}
.about_author {
    position: relative;
    padding: 40px;
    background: #f8f9fb;
    min-height: 700px;
    img{
        max-width: 100%;
    }
    :global(.slick-slide){
        >div{
            padding: 10px;
        }
        img{
            max-width: 100%;
        }
    }
    :global(.slick-arrow){
        background: #d9dadf;
        color: #3a414d;
        text-align: center;
        width: 32px;
        height: 32px;
        padding-top: 8px;
        box-sizing: border-box;
        border-radius: 50%;
        opacity: 0.5;
        &:hover{
            background: #d9dadf; 
        }
        svg{
            fill:#535b69;
            font-size: 16px;
        }
    }

}
.about_text{
    display: flex;
    flex-direction: column;
    padding: 0 0 0 80px;
    justify-content: center;
    height: 100%;
    font-family: "NotoSerif-Regular";
    p{
        font-size: 24px;
    }
    span{
        font-size: 17px;
        display: flex;
        line-height: normal;
        align-items: center;
        margin-bottom: 8px;
        svg{
            fill:#ffd240;
            font-size: 24px;
            margin-right: 10px;
        }
    }
}
.my_serv{
    padding: 80px 0;
    background: #ffffff;
}
.my_coursce{
    padding: 80px 0; 
    background: url('../assets/images/fonn.png');
}
.home_page{
    h4{
        text-align: center;
        font-size: 28px;
        font-family: "NotoSerif-Regular";
        color:#151b2c;
        font-weight: normal;
        margin-bottom: 5px;
    }
    .landing_title {
        background: linear-gradient(to right, transparent, #6c5ffc, transparent);
        height: 1px;
        margin: 0px auto;
        width: 150px;
        display: block;
        margin-block-end: 10px;
    }
    .title_bl{
        margin-bottom: 100px;
        @media screen and (max-width: 768px) {
            margin-bottom: 50px;
        }
    }
} 
.around_k{
    position: relative;
    img{
        position: relative;
        z-index: 1;
    }
    .around_bg{
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -45%;
        z-index: 0;
        border-radius: 50%;
        width: 90%;
        background: rgb(73 80 99 / 14%);
        height: 90%;
        
    }
}
.my_serv_wp{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px;
    justify-content: flex-start;
    .my_serv_item{
        width: 20%;
        box-shadow: 0 0 40px rgba(8, 7, 16, 0.1);
        background: #fff;
        padding: 16px;
        border-radius: 10px;
        margin: 0 10px 16px 10px;
        p{
            font-size: 16px;
            color: #151b2c;
            font-family: "NotoSerif-Regular";
            padding: 10px 0;
            margin-bottom: 10px;
        }
        span{
            /*background: #f0efff;*/
            background: #ffe79a;
            padding: 8px 8px;
            display: block;
            text-align: center;
            font-family: "NotoSerif-Regular";
            margin-right: 5px;
            width: 50%;
            border-radius: 4px;
        }
        a{
            font-size: 14px;
            text-align: center;
            text-decoration: none;
            font-family: "NotoSerif-Regular";
            padding: 8px 16px;
            background: #757d8c;
            height: auto;
            border-radius: 4px;
            display: block;
            width: 100%;
            margin-left: 5px;
            color: #fff;
        }
    }
}
.my_serv_flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.img_bl{
    height: 260px;
    border-radius: 10px 10px;
    background-position: 50% 50%;
    background-size: cover;
}
.my_coursce{
    img{
        width: 100%;
        border-radius: 0px;
    }
}
.my_coursce_new{
    ul{
        li{
        list-style-type: none;
        align-items: center;
        flex-wrap: wrap;
        font-family: "Roboto";
        margin-bottom: 9px;
        align-items: center;
        .rek{
            display: block;
            width: 100%;
            padding: 3px 0;
            margin-left: 35px;
            font-family: "Roboto-Medium";
        }
        }
        a{
            margin-left: 10px;
            color:#151b2c;
            font-family: "Roboto-Medium";
        }
    }
}
.my_coursce_d{
    margin-bottom: 20px;
    p{
        font-size: 18px;
        margin-top: 0;
        font-family: "NotoSerif-Bold";
        color: #41495d;
        text-transform: uppercase;
        display: block;
        font-weight: normal;
        margin-bottom: 14px;
        span{
            font-family: "Roboto";
            text-transform: none;
        }
        b{
            font-weight: normal;
            text-decoration: line-through;
        }
        i{
            font-size: 20px;
            color: red;
            font-style: normal;
      
        }
 
    }
    span{
        font-size: 14px;
        font-family: "NotoSerif-Regular";
        color:#151b2c; 
        line-height: normal;
        display: block;
    }
    ul{
        margin: 0;
        padding: 0;
        li{
            list-style-type: none;
            font-size: 14px;
            display: flex;
            color:#333;
            font-family: "Roboto";
            margin-bottom: 9px;
            align-items: center;
            svg{
                fill: #ffd240;
                font-size: 24px;
                margin-right: 10px;
            }
        }
    }

}
.my_coursce_dr{
    padding-left: 30px;
}
.my_coursce_dl{
    padding-right: 24px;
}
.my_contacts{
    background: #f8f9fb; 
    padding: 80px 0;
}
.my_contacts{
    label{
        font-size: 16px!important;
        font-family: "NotoSerif-Regular"; 
        color:#151b2c;   
    }
    input, textarea{
        padding: 8px 16px;
        font-size: 16px;
        font-family: "NotoSerif-Regular"; 
    }
    p{
        font-size: 14px;
        font-family: "Roboto";
        color: #484d5c;
        margin: 0 0 16px 0;
        text-decoration: none;
        span{
            display: block;
            font-size: 16px;
            font-family: "NotoSerif-Regular"; 
            color:#151b2c;
            background:none;
        }

    }
    a{
        font-size: 14px;
        font-family: "NotoSerif-Regular"; 
        color: #484d5c;
        text-decoration: none;
    }
    button{
        background: #757d8c;
        height: auto;
        padding: 8px 16px;
    }
    .cont_righ{
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-left: 50px;
        div{
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
            align-items: center;
            margin-bottom: 16px;
            >span{
                background: #f0efff;
                border-radius: 50%;
                height: 56px;
                display: block;
                text-align: center;
                width: 56px;
                margin-right: 20px;
                svg{
                    margin-right: 24px;
                    font-size: 24px;
                    margin: 14px auto;
                    color:#3a414d;
                }
            }
        }
    }
}

.banner_one {
    background-color: #41495d;
    position: relative;
    padding-bottom: 210px;
    padding-top: 308px;
    height: 100%;
    .titl_bb{
        text-transform: uppercase;
        font-family: "Rubik-Medium";
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 50px;
    }
  }
  
  .banner_one::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../assets/images/banner-1--1-bg.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 6%;
  }
  
  .banner_one .container {
    position: relative;
  }
  
  .banner_one__moc {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-animation: planeMove2 5s ease-in infinite;
            animation: planeMove2 5s ease-in infinite;
  }
  
  .banner_one__content h3 {
    margin: 0;
    font-size: 54px;
    font-weight: bold;
    font-weight: normal;
    line-height: 54px;
    color: #fff;
    font-family: "NotoSerif-Regular";
    margin-bottom: 26px;
  }
  
  .banner_one__content h3 span {
    display: inline-block;
    line-height: 1em;
    position: relative;
    font-family: "NotoSerif-Regular";
    font-size: 26px;
    font-weight: normal;
    @media screen and (max-width: 850px) {
        display: block; 
        padding-top: 20px;  
        width: 300px;
    }
  }
  
  .banner_one__content h3 span::before {
    content: "";
    background: linear-gradient(to right, transparent, #6c5ffc, transparent);
    height: 2px;
    width: 100%;
    display: block;
    position: absolute;
    bottom: -13px;
  }
  
  .banner_one__content p {
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    font-family: "Rubik-Light";
    opacity: 90%;
    font-weight: normal;
    margin: 0;
    margin-bottom: 44px;
  }
  
  .banner_one__btn {
    font-size: 16px;
    border-radius: 8px;
    font-family: "Rubik-Medium";
    padding: 14px 40px;
    background-image: linear-gradient(90deg, #ffd240 0%, #fa9442 100%);
    background-size: 150% auto;
    transition: 500ms;
    color:#41495d;
    &:hover{
        color:#ffffff;
        background-position: right;
    }
  }
  
  .banner_one__video {
    display: inline-block;
    position: absolute;
    top: -110px;
    right: -70px;
  }
  
  .banner_one__video > img {
    border-radius: 50%;
    -webkit-box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.2);
  }
  
  .banner_one__video_btn {
    width: 192px; 
    height: 192px;
    border-radius: 50px;
    background-image: -webkit-gradient(linear, left top, right top, from(#ffd240), color-stop(51%, #fa9442), to(#ffd240));
    background-image: linear-gradient(90deg, #ffd240 0%, #fa9442 51%, #ffd240 100%);
    border: 27px solid #41495d;
    display: block;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: absolute;
    top: calc(68% - 96px);
    left: calc(4% - 96px);
    color: #fff;
    font-size: 40px;
    background-size: 200% auto;
    -webkit-transition: 500ms;
    transition: 500ms;
  }
  
  .banner_one__video_btn:hover {
    color: #fff;
    background-position: right;
  }
  .video_bl{
    height: 90vh;
    display: block;
    margin: 0 ;
  }
  .video_bl2{
    width: 80%;
  }

  .pricee{
    padding: 0 10px;
    span{
        font-size: 14px;
        font-family: "NotoSerif-Bold";
    }
    p{
        font-size: 18px;
        text-transform: uppercase;
        font-family: "NotoSerif-Bold";
        b{
            text-decoration: line-through;
            font-size: 18px;
        }
        i{
            font-style: normal;
            color: red;
            font-size: 20px;
        }
    }
  }

  @media screen and (max-width: 1024px) {

  .my_serv_wp{
    display: flex;
    margin: 0 -10px;
    justify-content: flex-start;

    .my_serv_item{
        width: calc(100%/3 - 52px )
    }
    } 
    .my_coursce_dr{
        padding-left: 0px;
        order:3;
        flex: 100%;
        max-width: 100%;
    }
    .my_coursce_dl{
        padding-right:0px;
        flex: 50%;
        order:2;
        max-width: 50%;
    }
    .my_coursce_dfoto{
        flex: 50%;
        order:1;
        max-width: 50%;
        padding-right: 40px;
        padding-bottom: 40px;
    }
}
@media screen and (max-width: 850px) {
    .about_text{
        padding: 0;
    }
    .my_serv_wp{
        .my_serv_item{
            width: calc(100%/2 - 52px )
        }
        } 
        .about_author{
            :global(.ant-row){
                flex-direction: column;
                :global(.ant-col){
                    flex: 100%;
                    max-width: 100%;
                }
            }
        }
        
.banner_one {
    background-color: #41495d;
    position: relative;
    padding-bottom: 40px!important;
    padding-top: 65px;
    height: 100%;
  }
  .banner_one__video {
    display: inline-block;
    position: relative;
    top: 0px;
    right: 0px;
    display: block;
    width: 66%;
    margin: 0 auto;
    img{
        width: 100%;
    }
  }
  .slider_bl{
    overflow: hidden;
    height: auto;
    position: relative;
    :global(.ant-col-12){
        flex: 100%;
        max-width: 100%;
    }
}
.banner_one__video_btn {
    width: 160px;
    height: 160px;
}
.cont_righ{
    padding-left: 0!important;
    padding-top: 40px;
  }
}

@media screen and (max-width: 768px) {
   /* .my_coursce_d{
        b{
            display: block;
        }
    }*/
.banner_one__video {
    display: inline-block;
    position: relative;
    top: 0px;
    right: 0px;
    display: block;
    width: 79%;
    margin: 0 auto;
    img{
        width: 100%;
    }
  }
  .banner_one__content h3 {
    font-size: 43px;
  }
  .my_coursce_d{
  
    p{  margin-bottom: 0px;}
}
.my_serv{
    padding: 40px 0;
}
.video_bl2{
    display: none;
}
}
@media screen and (max-width: 600px) {
    .my_serv_wp{
        .my_serv_item{
            width: calc(100% - 52px )
        }
        } 
    .banner_one__video_btn {
        width: 110px;
        height: 110px;
    }
    .banner_one__moc {
        position: absolute;
        top: 23px;
        right: 0;
        width: 103%;
    
      }
      .banner_one__content h3 {
        font-size: 34px;
      }
      .banner_one__content h3 span {
        font-size: 22px;
        padding-top: 7px;
      }
      .video_bl{
        height: auto;
        width: 100%;
        display: block;
        margin: 0 ;
      }
      .about_author{
        padding: 0;
        overflow: hidden;
      
    }
    .home_page{
        h4{
            margin-top: 0;
            padding-top: 25px;
        }
}
}
@media screen and (max-width: 500px) {
    .banner_one__video_btn {
        width: 80px;
        height: 80px;
    }
    .banner_one__video {
        width: 100%;
      }
      .banner_one__video_btn {
        top: calc(68% - 31px);
        left: calc(4% - 31px);
      }
} 