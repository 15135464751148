@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.card_course{
    h4{
        font-size: 32px;
        margin: 16px 0; 
        color: #151b2c;
        font-weight: 400;
        font-family: "NotoSerif-Regular";
    }
    .less_wrp{
        a{ 
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #333;
            justify-content: flex-start;
            background: #edf0f7;
            padding: 16px;
            margin-top: 16px; 
            font-family: "NotoSerif-Regular";
            border-radius: 6px;
            text-decoration: none;
            margin-bottom: 16px;
            div{
                width: calc(100% - 32px);
            }
            b{
                font-size: 18px;
                margin: 0 0 6px 0;
                display: block;
            }
            p{
                font-size: 14px;
                margin: 0;
            }
            span{
                font-size: 24px;
                background: #fff;
                height: 32px;
                display: block;
                margin-right: 16px;
                width: 32px;
                line-height: 26px;
                padding: 5px;
                text-align: center;
                border-radius:50%;
            }
         }
    }  
}
.pagination_wp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    a{
        display: block;
        font-size: 14px;
        font-family: "Roboto";
        color: #41495d;
    }
   
}
.video_txt{ 
    margin-top: 32px;
    p{
        color: #333;
        font-family: "NotoSerif-Regular";
        font-size: 14px;
        margin-bottom: 8px;
        line-height: normal;
        margin-top: 0;
    }
}

.player_bl{
    background-color: #000000;
}
.video_titil{
    display: block;
    margin: 40px 0 20px 0;
    font-size: 18px;
    font-weight: 400;
    font-family: "NotoSerif-Regular";
}