@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";


.admin_wp {
  h4 {
    font-size: 24px;
    color: #545454;
    font-weight: 400;
    font-family: "NotoSerif-Regular";
  }

  .flex_course {
    display: flex;
    align-items: center;
    flex-direction: row;
    //margin:0 -20px;
    flex-wrap: wrap;

    a {
      width: 30%;
      box-shadow: 0 0 40px rgba(8, 7, 16, 0.1);
      margin: 20px;
    }
  }
}
.flex_course{
  :global(.ant-btn-dangerous){
    display: none!important;
  }

}


