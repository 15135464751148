@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.course_wp{
  border-radius: 10px;
  background: #fff;
  .course_bg{
    border-radius: 10px 10px 0 0;
    height: 300px;
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    span{
      font-size: 14px;
      color:#fff;
    }
  }
  .course_text{
    padding: 0 16px 16px 16px;
    p{
      font-size: 17px;
      margin-bottom: 0;
      font-weight: 400;
      font-family: "NotoSerif-Regular";
      color:#151b2c;
    }
    span{
      font-size: 12px;
      margin-bottom: 0;
      font-weight: 400;
      font-family: "NotoSerif-Regular";
      color:#848484;
    }
  }
}
