@import "../../src/scss/variables.scss";
@import "../../src/scss/fonts";
@import "../../src/scss/mixins";

.cabinet{
    padding-top: 82px;
    :global(.header){
        background-color: #41495d!important;  
    }
    .header{
        background-color: #41495d!important;  
       
      
    }
}
.cabinet_admin.cabinet{
    padding-top: 0;
}