@import "../../scss/variables";
@import "../../scss/fonts";
@import "../../scss/mixins";


.admin_wp{
  h4{
    font-size: 24px;
    color: #151b2c;
    font-weight: 400;
    font-family: "NotoSerif-Regular";
  }
  .one_course{
    border: 1px solid #ccc;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  form{
    width: 70%;
  input{
    padding: 8px 16px;
    font-size: 16px;
    height: auto;
  }
  label{
    font-size: 18px;
  }
  } 
  :global(.ant-picker){
    width: 100%;
  }
  :global(.ant-select-selection-placeholder){
    font-size: 16px!important;
    line-height: 40px!important;
    
  }
  :global(.ant-select-selection-search-input){
   height: 46px!important; 
  }
  :global(.ant-select-selector), :global(.ant-picker){
    height: 46px!important;
    line-height: 40px;
  }
  
}

@media screen and (max-width: 768px) {
  .admin_wp{
    form{
      width: 100%;
    }
  }

}