@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";


.admin_wp{
  form{
    width: 70%;
  input{
    padding: 8px 16px;
    font-size: 16px;
  }
  label{
    font-size: 18px;
  }
  .ant-btn-primary{
    background-color: #6c5ffc!important;
    font-size: 16px;
  }
  } 

  h4{
    font-size: 26px;
    color: #151b2c;
    font-weight: 400;
    margin: 8px 0;
    font-family: "NotoSerif-Regular";
  }
  .flex_course {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin:0 -20px;
    flex-wrap: wrap;
    @media screen and (max-width: 500px) {
      margin: 0;
    }
    a{
      width: 30%;
      box-shadow: 0 0 40px rgba(8,7,16,0.1);
      margin: 20px;
      min-width: 350px;
      @media screen and (max-width: 500px) {
        min-width: none;
        max-width: 100%;
        width: 100%;
        margin: 20px 0;
      }
    }
}
}
.tabs_wp{
 > div{
    font-size: 14px;
    font-family: "NotoSerif-Regular";
    color: #545455;
  }
form{
  width: 100%;
}
}

