@import "fonts";
@import "variables";

:-internal-direct-focus {
  outline-color: transparent;
  outline-style: auto;
  outline-width: 0;
}

*::-webkit-scrollbar {
  height: 12px;
  width: 2px;
  font-weight: normal;
}
*::-webkit-scrollbar-track {
  background: $background_site;
}
*::-webkit-scrollbar-thumb {
  background-color: $background_block;
  border-radius: 5px;
  /*border: 3px solid orange;*/
}
$primary-color: transparent;
html,
#root {
  background-color: #fff;
  scrollbar-color: $background_site;
}

body {
  background: #fff;
  color: #333;
  scrollbar-color: $background_site;
  margin: 0;
  position: relative;
  /* font-family: -apple-system, "BlinkMacSystemFont", "Roboto", sans-serif !important;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, "Rubik-Regular", sans-serif;
  line-height: 1.4715;
  font-size: 14px;
  // font-weight: 400;

  &.ant-scrolling-effect {
    width: 100% !important;
    position: fixed;
    overflow: auto !important;
  }
}

.breadcrumb_wrp{
  font-family: "Roboto";
  margin: 20px 0;
  font-size: 12px;
  border-radius: 4px;
  padding: 8px 16px;
  background: rgb(249, 249, 250);
  a{
    &:hover{
      background: transparent;
      color:#6c5ffc;
    }
  }
}
.ant-btn-primary{
  background-color:#6c5ffc;
  font-size: 16px;
  padding: 8px 32px;
  height: auto;
  line-height: normal;
  span{
    padding: 0;
    margin: 0;
  }
}
.container{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 16px;
  position: relative;
}
.slick-slider img {
  width: 100%;
}
.relative{
  position: relative;
}
.ant-tabs-tab-active{
  .ant-tabs-tab-btn{ 
    color:#151b2c!important;
  }  
}
.ant-tabs .ant-tabs-ink-bar{
  background: #151b2c!important;
}
.title_add{
  font-size: 26px;
  color: #151b2c;
  font-weight: 400;
  margin: 8px 0;
  font-family: "NotoSerif-Regular";
}
.wrp_fl{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
