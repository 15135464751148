@import "../../scss/variables.scss";
@import "../../scss/mixins";

.logo_auth img {
  width: 220px;
  margin: 0 auto 40px;
  display: block;
}
body{
  height: 100%;
 
  #root{
    background-color: transparent;
    height: 100%;
    position: relative;
    width: 100%;
  }
  .block_login{
    background: #614385; 
    background: -webkit-linear-gradient(to right, #516395, #3e4553); 
    background: linear-gradient(to right, #516395, #3e4553);
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    flex-direction: column; 
    width: 100%;
    height: 100vh;
    .ant-checkbox-checked .ant-checkbox-inner{
      background-color: #516395;
      border-color: #516395;
    }
    .little_text2{
      font-family: "NotoSerif-Regular"; 
      font-size: 14px;
    }
    .text_center{
      text-align: center;
    }
    .title_auth{
      font-size: 24px;
      text-align: center;
      color: #333;
      font-weight: 400;
      font-family: "NotoSerif-Regular";
      padding: 0;
      margin: 0 0 32px 0;
    }
    input{
      padding: 8px 16px;
      font-size: 16px;
    }
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      -moz-appearance: textfield;
      margin: 0;
    }
    input[type='number'],
    input[type="number"]:hover,
    input[type="number"]:focus {
      appearance: none;
      -moz-appearance: textfield;
    }
    .login-form-button{
      font-size: 16px;
      height: auto;
      background: #516395;
      padding:8px 0;
      font-family: "NotoSerif-Regular";
      &:hover{
        background:rgba(81, 99, 149, 0.9);
      }
    }
    .block_pass{
      span{
        font-family: "NotoSerif-Regular"; 
        font-size: 14px;
      }
      label, a{
        font-size: 14px;

      }
    }
 
    .login-form-forgot{
      color:#6c5ffc!important;
      font-family: "NotoSerif-Regular"; 
    }
    .form_content{
      box-shadow: 0 0 40px;
      border-radius: 10px;
      width: 420px;
      max-width: 96%;
      padding: 40px 20px;
      background-color: #fff;
      position: relative;
    }
    .no_acc{
      font-family: "NotoSerif-Regular";
      color:#333;
      text-align: center;
      position: relative;
      a{
        color:#6c5ffc!important;
        margin-left: 8px;
        text-decoration: none;
      }
      &:before{
          content: "";
          position: absolute;
          width: 28%;
          height: 1px;
          inset-inline-start: 0;
          inset-inline-end: 0;
          background-color: #e9e9ef;
          inset-block-start: 10px;
          @media screen and (max-width: 768px) {
            width: 20%;
          }
      }
      &:after{
        content: "";
        position: absolute;
        width: 28%;
        height: 1px;
        inset-inline-start: auto;
        inset-inline-end: 0;
        background-color: #e9e9ef;
        inset-block-start: 10px;
        @media screen and (max-width: 768px) {
          width: 20%;
        }
      }
    }
    .no_acc1{
      font-family: "NotoSerif-Regular";
      color:#333;
      text-align: center;
      position: relative;
    }
    .no_acc1{
      font-family: "NotoSerif-Regular";
      color:#333;
      text-align: center;
      position: relative;
      &:after{
        content: "";
        position: absolute;
        width: 20%;
        height: 1px;
        inset-inline-start: auto;
        inset-inline-end: 0;
        background-color: #e9e9ef;
        inset-block-start: 10px;
      }
      a{
        color:#6c5ffc!important;
        margin-left: 8px;
        text-decoration: none;
      }
      &:before{
        content: "";
        position: absolute;
        width: 20%;
        height: 1px;
        inset-inline-start: 0;
        inset-inline-end: 0;
        background-color: #e9e9ef;
        inset-block-start: 10px;
      }
    }
  }
}
.input_gr{
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
  }
  .react-code-input{
    display: flex!important;
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  body{
    .block_login{
      .form_content{
        width: 90%;
        box-sizing: border-box;
        max-width: 96%;
      }
    }
  }

}