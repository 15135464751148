.image-preview{
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: #141414;
   /* box-shadow: 1px 1px 3px 0 #000;*/
   border: 1px solid #545860;
    width: 96px;
    height: 96px;
}
.image-preview img{
    display: block;
    height: 100%;
    margin: 0 auto;
    border-radius: 0;
}