@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.header{
  /*background: #817F8C;*/
  background: #3e4553;
  padding: 20px 0;
}
.logo{
  width: 220px;
}
.menu{
  margin: 0;
  li{
    display: inline-block;
    margin-right: 5%;
    list-style-type: none;
    a{
      font-size: 16px;
      color:#fff;
      font-family: "Roboto";
      padding: 0;
    }
  }
}
.textright{
  justify-content: flex-end;
  text-align: right;
}
.but_menu{
  cursor: pointer;
  svg{
    fill:#fff;
    font-size: 24px;
  }
}

.flex_dd{
  display: flex;
  align-items: center;
}


@media screen and (max-width: 1024px) {

  .header{
    padding: 0;
  }
  :global(.ant-anchor){
    flex-direction: column;
  }
  :global(.ant-anchor-link){
    display: block;
    width: 100%;
    padding: 10px 0 !important;
    padding-inline:0!important;
    a{
      font-size: 16px;
      padding: 10px 0;
      color:#ffffff;
    }
  }
}


@media screen and (max-width: 768px) {

  .textright{
    justify-content: flex-end;
    text-align: right;
    display: flex;
    width: 50%;
    flex: 50%;
    max-width: none;
  }
.menu li{
  display: block;
  padding: 16px 0;
}
:global(.ant-drawer-header-title){
  justify-content: flex-end;
}
.menu{
padding: 0;
margin: 0;
}
:global(.ant-btn-primary){
  padding: 8px 16px;
}

}

@media screen and (max-width: 600px) {
  .header{
    background: #41495d;
    .logo{
        width: 160px;
    }
  }
}