@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Regular.eot");
  src: local("./fonts/Roboto/Roboto-Regular"), local("Roboto-Regular"),
    url("./fonts/Roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Roboto/Roboto-Regular.woff") format("woff"),
    url("./fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("./fonts/Roboto/Roboto-Medium.eot");
  src: local("./fonts/Roboto/Roboto-Medium"), local("Roboto-Medium"),
    url("./fonts/Roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Roboto/Roboto-Medium.woff") format("woff"),
    url("./fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  src: local("Roboto-Medium"), url("./fonts/Roboto/Roboto-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-bold";
  src: url("./fonts/Roboto/Roboto-Bold.eot");
  src: local("./fonts/Roboto/Roboto-Bold"), local("Roboto-Bold"),
    url("./fonts/Roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Roboto/Roboto-Bold.woff") format("woff"),
    url("./fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  src: local("Roboto-bold"), url("./fonts/Roboto/Roboto-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

/*
@font-face {
  font-display: swap;
  font-family: "Roboto-bold";
  src:
          local('Roboto-bold'),
          local('Roboto-bold'),
          url('./fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
          url('./fonts/Roboto/Roboto-Bold.woff') format('woff'),
          url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}*//*

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Black.eot");
  src: local("./fonts/Roboto/Roboto-Black"), local("Roboto-Black"),
    url("./fonts/Roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Roboto/Roboto-Black.woff") format("woff"),
    url("./fonts/Roboto/Roboto-Black.ttf") format("truetype");
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Black.ttf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto/Roboto-Light.eot");
  src: local("./fonts/Roboto/Roboto-Light"), local("Roboto-Light"),
    url("./fonts/Roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Roboto/Roboto-Light.woff") format("woff"),
    url("./fonts/Roboto/Roboto-Light.ttf") format("truetype");
  src: local("Roboto"), url("./fonts/Roboto/Roboto-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
*/
@font-face {
  font-family: "NotoSerif-Regular";
  src: url("./fonts/Noto_Serif/NotoSerif-Regular.eot");
  src: local("./fonts/Noto_Serif/NotoSerif-Regular"), local("NotoSerif-Regular"),
    url("./fonts/Noto_Serif/NotoSerif-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Noto_Serif/NotoSerif-Regular.woff") format("woff"),
    url("./fonts/Noto_Serif/NotoSerif-Regular.ttf") format("truetype");
  src: local("Noto_Serif"), url("./fonts/Noto_Serif/NotoSerif-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NotoSerif-Bold";
  src: url("./fonts/Noto_Serif/NotoSerif-Bold.eot");
  src: local("./fonts/Noto_Serif/NotoSerif-Bold"), local("NotoSerif-Bold"),
    url("./fonts/Noto_Serif/NotoSerif-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Noto_Serif/NotoSerif-Bold.woff") format("woff"),
    url("./fonts/Noto_Serif/NotoSerif-Bold.ttf") format("truetype");
  src: local("Noto_Serif"), url("./fonts/Noto_Serif/NotoSerif-Bold.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik-Regular";
  src: url("./fonts/Rubik/Rubik-Regular.eot");
  src: local("./fonts/Rubik/Rubik-Regular"), local("Rubik-Regular"),
    url("./fonts/Rubik/Rubik-Regular.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Rubik/Rubik-Regular.woff") format("woff"),
    url("./fonts/Rubik/Rubik-Regular.ttf") format("truetype");
  src: local("Rubik-Regular"), url("./fonts/Rubik/Rubik-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik-Medium";
  src: url("./fonts/Rubik/Rubik-Medium.eot");
  src: local("./fonts/Rubik/Rubik-Medium"), local("Rubik-Medium"),
    url("./fonts/Rubik/Rubik-Medium.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Rubik/Rubik-Medium.woff") format("woff"),
    url("./fonts/Rubik/Rubik-Medium.ttf") format("truetype");
  src: local("Rubik-Medium"), url("./fonts/Rubik/Rubik-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik-Light";
  src: url("./fonts/Rubik/Rubik-Light.eot");
  src: local("./fonts/Rubik/Rubik-Light"), local("Rubik-Light"),
    url("./fonts/Rubik/Rubik-Light.eot?#iefix") format("embedded-opentype"),
    url("./fonts/Rubik/Rubik-Light.woff") format("woff"),
    url("./fonts/Rubik/Rubik-Light.ttf") format("truetype");
  src: local("Rubik-Light"), url("./fonts/Rubik/Rubik-Light.ttf");
  font-weight: 300;
  font-style: normal;
}