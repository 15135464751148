@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.header_fix{
  background-color: #41495d;
}
.header{
  padding: 24px 0;
  position: fixed;
  top: 0;
  width: 100%;
  left:0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 10;
}
.logo{
  width: 220px;
  a{
    display: block;
    margin: 0;
    padding: 0;
  }
}
.menu{
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  li{
    display: inline-block;
    list-style-type: none;
    a{
      font-size: 16px;
      color:#fff;
      font-family: "Roboto";
      padding: 0;
      font-weight: normal;
    }
    span{
      font-size: 16px;
      color:#fff;
      font-family: "Roboto";
      padding: 0;
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.textright{
  justify-content: flex-end;
  text-align: right;
}
:global(.ant-anchor){
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  a{
    font-size: 16px;
    color:#fff!important;
    font-family: "Roboto";
    padding: 0;
    font-weight: normal;
  }
}
:global(.ant-anchor-wrapper){
  width: calc(100% - 170px)
}
:global(.ant-anchor-ink){
&:before{
  content: none!important;
}
}
.but_menu{
  cursor: pointer;
  svg{
    fill:#fff;
    font-size: 24px;
  }
}
.flex_dd{
  display: flex;
  align-items: center;
}

:global(.ant-drawer-content){
  background: #41495d!important;
  color:#fff;
}
:global(.ant-drawer-close){
  order:2;
  margin-inline-end:0!important;
  svg{
    fill:#fff 
  }
}
:global(.ant-drawer-header-title){
  p, a{
    color:#fff;
    font-size: 16px;
    font-family: "Rubik-Regular";
    font-weight: normal;
  }
}


@media screen and (max-width: 1024px) {
  .header{
    padding: 0;
  }
  :global(.ant-anchor){
    flex-direction: column;
  }
  :global(.ant-anchor-link){
    display: block;
    width: 100%;
    a{
      font-size: 16px;
      padding: 10px 0;
    }
  }
  :global(.ant-drawer-title){
    span{
      color:#fff;
      cursor: pointer;
      font-weight: normal;
    }
  }
}


@media screen and (max-width: 600px) {
  .header{
    background: #41495d;
    .logo{
      a{
        width: 160px;
      }
    }
  }
}