@import "../../scss/variables.scss";
@import "../../scss/fonts";
@import "../../scss/mixins";

.footer{
  position: relative;
  background: #363b46;
  padding: 40px 0 0 0;
  color: #fff;
  p{
    font-size: 16px;
    color:#fff;
    font-family: "NotoSerif-Regular";
    margin-bottom: 32px;
    margin-top: 0;
    img{
      height: 16px;
    }
    b{
      font-size: 10px;
      font-weight: normal;
    }
  }
  a{
    font-size: 12px;
    text-decoration: underline;
    color:#f2f2f2;
    font-family: "NotoSerif-Regular"; 
    margin: 0;    
  }
  .logo{
    margin-bottom: 0;
    width: 100%;
    display: block;
    img{
      width: 60%;
    }
  }
  span{
    display: block;
    font-weight: normal;
    font-family: "Roboto";
    color: #949699;
  }
}
.footer_social{
  display: flex;
  align-items: center;
  flex-direction: row;
  a{
    margin-right: 8px;
    background: #757d8c;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    text-align: center;
    padding-top: 5px;
    box-sizing: border-box;
    svg{
      font-size: 22px;
      fill: #fff;
    }
    img{
      height: 22px;
      padding: 0;
      margin: 0;
    }
    &:hover{
      transition: 500ms;
      background: #ffd240;
    }
  }
}
.footer_right{
  text-align: right;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
}
.footer_bot{
  width: 100%;
  border-top:1px solid #3e4553;
  padding: 24px 0;
  text-align: center;
   margin-top: 24px;
  p{
    margin-bottom: 0;
    padding: 0;
    font-size: 14px;
    font-family: "Roboto";
    color: #949699;
  }
}
.foot{
  display: flex;
  height: 100px;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .footer_right{
    align-items: center;
    padding-top: 20px;
  }
  .footer{ 
    text-align: center;
    .logo{
      img{
        width: 200px;
      }
    }
    p{
      margin-bottom: 16px;
    }
    :global(.ant-col){
      margin-bottom: 16px;
    }
  }

  .foot{
    height: auto;
  }
}